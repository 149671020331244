<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Pedidos</h4>
                  <h1>Almacén</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <!--<ion-button router-link="/modules/restaurant/tpv">
                      <ion-icon color="dark" slot="icon-only" :icon="addCircleOutline"></ion-icon>
                    </ion-button>-->
                    <ion-button @click="exportCSV($event)">
                      <ion-icon color="dark" slot="icon-only" :icon="cloudDownloadOutline"></ion-icon>
                    </ion-button>
                    <ion-button @click="confirmPrintAllTickets($event)" v-if="selectedElements.length && evolbeUser.business[0].rol !== 'analyst'">
                      <ion-icon color="dark" slot="icon-only" :icon="printOutline"></ion-icon>
                    </ion-button>
                    <ion-button @click="confirmCancelAllProducts($event)" v-if="selectedElements.length && evolbeUser.business[0].rol !== 'analyst'">
                      <ion-icon color="danger" slot="icon-only" :icon="closeCircleOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Fecha')" field="dateCreated" header="Fecha" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ fechaDia(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>
                <Column v-if="selectedColumns.includes('Nombre')" field="customer.firstName" header="Nombre" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field table-primary-field">{{ slotProps.data.customer.firstName.toLowerCase() }}, {{ slotProps.data.customer.lastName.toLowerCase() }}</span>
                  </template>
                </Column>
                <Column v-if="selectedColumns.includes('Dirección Entrega')" field="customer.address" header="Direccíon Entrega">
                  <template #body="slotProps">
                        <span v-if="slotProps.data.delivery && slotProps.data.customer" class="table-field">{{ slotProps.data.customer.address }}</span>
                        <span v-if="!slotProps.data.delivery || !slotProps.data.customer.address" class="table-field">Recogida en el local</span>
                  </template>
                </Column>
                <Column v-if="selectedColumns.includes('Fecha Entrega')" field="deliveryDate" header="Entrega" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.deliveryDate" class="table-field">~{{ slotProps.data.deliveryDate }}</span>
                  </template>
                </Column>
                <Column v-if="selectedColumns.includes('Total')" field="total" header="Total" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field price">{{ slotProps.data.total.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>
                <Column v-if="selectedColumns.includes('Estado')" field="status" header="Estado" :sortable="true">
                  <template #body="slotProps">
                     <ion-chip v-if="slotProps.data.status === 0" color="dark"><ion-label >Abandonado</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 1" color="tertiary" @click="nextStatus(slotProps.data)"><ion-label >Nuevo</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 2" color="secondary" @click="nextStatus(slotProps.data)"><ion-label >Procesando</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 3" color="warning" @click="nextStatus(slotProps.data)"><ion-label >Enviado</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 4" color="success"><ion-label >Completado</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 5" color="danger"><ion-label >Cancelado</ion-label></ion-chip>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>


                <!-- Datos del Pedido -->
                <Column v-if="selectedColumns.includes('ID')" field="objectID" header="ID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.objectID" class="table-field">#{{ slotProps.data.objectID }}</span>
                  </template>
                </Column>

                <!-- Datos del Cliente -->
                <Column v-if="selectedColumns.includes('UID')" field="customer.uid" header="UID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.uid }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Email')" field="customer.email" header="Email" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.email }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Teléfono')" field="customer.phone" header="Teléfono" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.phone }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Ciudad')" field="customer.city" header="Ciudad" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.city }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Ciudad"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Código Postal')" field="customer.zipCode" header="Código Postal" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.zipCode }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Código Postal"/>
                  </template>
                </Column>
                

                <!-- Datos de los Totales -->
                <Column v-if="selectedColumns.includes('Subtotal')" field="subtotal" header="Subtotal" dataType="numeric" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.objectID" class="table-field price">{{ slotProps.data.subtotal.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Cupón')" field="cupon.code" header="Cupón" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.cupon" class="table-field">{{ slotProps.data.cupon.code }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Código Cupón"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descuento')" field="coupon.discount" header="Dto." dataType="numeric" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.coupon" class="table-field">{{ slotProps.data.coupon.discount }}%</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>
                
                <Column v-if="selectedColumns.includes('Recargo')" field="surcharge" header="Recargo" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.surcharge" class="table-field price">{{ (3).toFixed(2) }}€</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Envío')" field="shipping" header="Envío" dataType="numeric" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.shipping" class="table-field price">{{ slotProps.data.shipping.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" suffix="%" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('I.V.A.')" field="tax" header="I.V.A." dataType="numeric" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.tax" class="table-field price">{{ slotProps.data.tax.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <!-- Otros Datos -->
                <Column v-if="selectedColumns.includes('Tipo Pago')" field="payment.method" header="Tipo Pago" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.payment && slotProps.data.payment.method === 'stripe'" class="table-field">Stripe</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.method === 'cash'" class="table-field">Efectivo</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.method === 'dataphone'" class="table-field">Datáfono</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="paymentMethods" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Estado Pago')" field="payment.status" header="Estado Pago" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.payment && slotProps.data.payment.status === 'succeeded'" class="table-field">Exitoso</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.status === 'pending'" class="table-field">Pendiente</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.status === 'error'" class="table-field">Erroneo</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="paymentStatuses" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Plataforma')" field="platform" header="Plataforma" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.platform" class="table-field">{{ slotProps.data.platform }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="platforms" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Platos')" field="items" header="Platos" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.items" class="table-field"><span v-for="dish in slotProps.data.items" :key="dish.id">{{ dish.name }}, </span></span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Plato"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Observaciones')" field="observations" header="Observaciones" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.observations" class="table-field">{{ slotProps.data.observations }}</span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" @click="openDetails({component: 'shop-storedOrder', data: slotProps.data})">
                        <ion-label>Detalles</ion-label>
                      </ion-button>
                  </template>
                </Column>
            </DataTable>               
        </section>
       </div>

      <Footer></Footer>
  </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonChip, IonButton, IonButtons, IonLabel, modalController } from '@ionic/vue';

import { trashOutline, cloudDownloadOutline, addCircleOutline, archiveOutline,optionsOutline, ellipsisVerticalOutline, eyeOutline, createOutline, printOutline, arrowUpOutline, closeCircleOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ConfirmPopup from 'primevue/confirmpopup';

import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Header from '@/components/Header.vue';

import bluetoothPrint from '@/utils/bluetooth-print.vue';
import otherPrint from '@/utils/other-print.vue';

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonChip,
    IonCol,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    IonButtons, 
    IonLabel,
    IonButton,
    ConfirmPopup,
    Calendar,
    InputNumber,
    Dropdown,
    Header,
    MultiSelect
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: null,
        openFilters: false,
        loading: true,
        lazyParams: null,
        totalRecords: 0,
        selectedColumns: null,
        columns: [
          'ID',
          'Fecha',
          'Fecha Entrega',
          'Estado',

          'UID',
          'Nombre',
          'Email',
          'Teléfono',
          'Dirección Entrega',
          'Ciudad',
          'Código Postal',

          'Subtotal',
          'Cupón',
          'Descuento',
          'Recargo',
          'Envío',
          'Total',
          'I.V.A.',

          'Tipo Pago',
          'Estado Pago',
          'Plataforma',
          'Platos',
          'Observaciones',
        ],

        statuses: [
          {
            label: 'Abandodano',
            color: 'dark',
            value: 0,
            command: () => {
              this.changeStatus(0);
            }
          },
          {
            label: 'Nuevo',
            color: 'tertiary',
            value: 1,
            command: () => {
              this.changeStatus(1);
            }
          },
          {
            label: 'Procesando',
            color: 'secondary',
            value: 2,
            command: () => {
              this.changeStatus(2);
            }
          },
          {
            label: 'Enviado',
            color: 'warning',
            value: 3,
            command: () => {
              this.changeStatus(3);
            }
          },
          {
            label: 'Completado',
            color: 'success',
            value: 4,
            command: () => {
              this.changeStatus(4);
            }
          },
          {
            label: 'Cancelado',
            color: 'danger',
            value: 5,
            command: () => {
              this.changeStatus(5);
            }
          },          
        ],

        paymentMethods: [
          {
            label: 'Stripe',
            value: 'stripe',
          },
          {
            label: 'Efectivo',
            value: 'cash',
          },
          {
            label: 'Datáfono',
            value: 'dataphone',
          },
        ],
        paymentStatuses: [
          {
            label: 'Exitoso',
            color: 'success',
            value: 'succeeded',
          },
          {
            label: 'Pendiente',
            color: 'warning',
            value: 'pending',
          },
          {
            label: 'Error',
            color: 'danger',
            value: 'error',
          },
        ],
        platforms: [
          {
            label: 'PWA',
            value: 'pwa',
          },
          {
            label: 'Android',
            value: 'android',
          },
          {
            label: 'iOS',
            value: 'ios',
          },
        ],
        
        generateTicket: false,
        ticket: {},
        generateTickets: false,
        tickets: [],
      };
    },
    computed: {
      ...mapState('shop', {
        elements: state => state.storedOrders,
      }),

      ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
      ...mapActions('shop',['getStoredOrders', 'deleteStoredOrders', 'updateStoredOrder']),
      ...mapActions(['openDetails']),

      // Initialization
      initFilters() {
          this.selectedColumns = [
            'Nombre',
            'Fecha',
            'Dirección Entrega',
            'Total',
            'Estado'
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'customer.city': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
              'customer.zipCode': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
              'coupon.code': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
              'subtotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'coupon.discount': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'shipping': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'tax': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'payment.method': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'payment.status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'platform': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      // Table
      onPage(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      onSort(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      // Single Functions
      nextStatus(data){
        if(this.evolbeUser.business[0].rol !== 'analyst'){
          const newData = data;
          newData.status = data.status + 1;
          this.updateStoredOrder({data: newData, id: newData.objectID});
        }
      },

      async printTicket(order){
          if(this.preferences && this.preferences.printType === 'bluetooth'){
            const modal = await modalController
              .create({
                component: bluetoothPrint,
                cssClass: 'auto-height',
                mode: 'ios',
                componentProps: {
                  data: order,
                  type: 'ticket',
                  copies: 1
                },
              })
            return modal.present();
          } else{
            const modal = await modalController
              .create({
                component: otherPrint,
                cssClass: 'auto-height',
                mode: 'ios',
                componentProps: {
                  data: order,
                  type: 'ticket',
                  copies: 1
                },
              })
            return modal.present();
          }
      },

      // Masive Functions
      confirmPrintAllTickets(event){
        this.$confirm.require({
            target: event.currentTarget,
            message: '¿Seguro de que quieres imprimir todos estos pedidos?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.printAllTickets();
            },
            reject: () => {
                //callback to execute when evolbeUser rejects the action
            }
        });
      },

      async printAllTickets(){
          this.selectedElements.forEach(async order => {
            if(this.preferences && this.preferences.printType === 'bluetooth'){
              const modal = await modalController
                .create({
                  component: bluetoothPrint,
                  cssClass: 'auto-height',
                  mode: 'ios',
                  componentProps: {
                    data: order,
                    type: 'ticket',
                    copies: 1
                  },
                })
              return modal.present();
            } else{
              const modal = await modalController
                .create({
                  component: otherPrint,
                  cssClass: 'auto-height',
                  mode: 'ios',
                  componentProps: {
                    data: order,
                    type: 'ticket',
                    copies: 1
                  },
                })
              return modal.present();
            }
          });
      },

      confirmCancelAllProducts(event){
        this.$confirm.require({
            target: event.currentTarget,
            message: '¿Seguro de que quieres cancelar estos elementos?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when evolbeUser confirms the action
                this.selectedElements.forEach(order => {
                  const newData = order;
                  newData.status = 5;
                  this.updateStoredOrder({data: newData, id: newData.objectID} );
                });

                this.selectedElements = [];
            },
            reject: () => {
                //callback to execute when evolbeUser rejects the action
            }
        });
      },

      // Others
      scrollToTop(){
        const scrollContent = document.querySelector('ion-content.bindingScrollContent');
        scrollContent.scrollToTop(1000)
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY-HH:mm');
      },

      fechaHora(date){
        return moment(date).format('HH:mm');
      },

      fechaDia(date){
        return moment(date).format('DD/MM/YYYY');
      },

      async loadLazyData() {
        this.loading = true;

        await this.getStoredOrders(this.lazyEvent).then(res => {
          this.loading = false;
          if(res){
            this.totalRecords = res.length; 
          }
        })
      },
    },
    created(){
      this.initFilters();
      this.loadLazyData();
      moment.locale('es');
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, eyeOutline, archiveOutline, ellipsisVerticalOutline, addCircleOutline, optionsOutline, createOutline, printOutline, closeCircleOutline, arrowUpOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline };
    }
});
</script>

<style scoped>
  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>